// react imports
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Favicon from 'react-favicon';

// page & component imports
import RootPage from './pages/RootPage'

// style imports
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/custom.css';


class App extends Component {
  render() {
    document.title = 'David Aaron Campos'
    return (
      <div>
        <RootPage />
      </div>
    )
  }
}

export default App;
