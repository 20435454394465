import React, { Component } from 'react';

import david from '../assets/david.jpg'

class Header extends Component {
    render() {
        return (
            <div className='container custom-section-container'>

                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <img
                        className='photo'
                        src={david}
                        style={{
                            borderRadius: '50%',
                        }}
                    />
                </div>

                <div
                    style={{
                        margin: '5% 20%',
                        textAlign: 'center',
                    }}
                >
                    <h1>Hi!</h1>

                    <p>
                        I am David, a technologist and music producer based in New York City.
                    </p>

                    <p>
                        I love to build internet and data machines that solve significant problems in the world. Also love writing chord progressions and melodies, designing sounds, and vibing to music.
                    </p>

                    <p>
                        I serve as data scientist at <a href='https://www.deltaemerald.com' target='_blank'>Delta Emerald Ventures</a>, an early-stage investment firm. Also I am a co-founder of <a href='https://www.inertia7.com' target='_blank'>Inertia7</a>, a social platform for sharing software and data science projects.
                    </p>

                    <p>
                        Connect with me on <a href='https://www.linkedin.com/in/dcamposliz/' target='_blank'>LinkedIn</a> and/or check out my music on <a href="https://www.youtube.com/channel/UCgtiYhQZiM6h5aegwaUeiPg" target='_blank'>YouTube</a>.
                    </p>
                </div>

                <div
                    style={{
                        margin: '10% 20%',
                        textAlign: 'center',
                        color: 'rgba(0,0,0,.15)',
                    }}
                    >
                    <br />
                    <p>
                        &copy; David Aaron Campos
                    </p>
                    <p>
                        All Rights Reserved 
                    </p>
                </div>
            </div>


        )
    }
}

export default Header;