import React, { Component } from 'react';

import Header from '../components/Header';

class RootPage extends Component {
    render() {
        return (
            <div>
                <Header />
            </div>
        )
    }
}

export default RootPage;